<template>
  <div class="haishi">
    <div class="search_box" v-if="show">
      <div class="mian1200 search">
        <el-autocomplete
          v-model="search"
          placeholder="请输入海师姓名、编号"
          :trigger-on-focus="false"
          :fetch-suggestions="querySearch"
          @select="handleSelect"
          @blur='find()'
          @keyup.enter.native="find"
        >
          <template slot="append">
            <div @click="find">
              <i class="el-icon-search"></i>
              搜索
            </div>
          </template>
          <template slot-scope="{ item }">
            <div class="name">{{ item }}</div>
          </template>
        </el-autocomplete>
      </div>
    </div>
    <div class="mian1200 content">
      <!-- 面包屑 -->
      <breadcrumb :list="this.$route" />
      <router-view />
    </div>
  </div>
</template>
<script>
// import login from '@/components/login/login.vue'
import breadcrumb from "@/components/el-breadcrumb/el-breadcrumb.vue";
import { getSearch } from "@/api/haishi.js";
export default {
  name: "haishi",
  components: { breadcrumb },
  data() {
    return {
      show: false,
      flag: false, //测试登录组件
      search: "", //分享
    };
  },
  mounted() {
    if (this.$route.path == "/haishi") {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    //实时模糊查询
    querySearch(queryString, cb) {
      getSearch({
        keyworld: queryString,
      }).then((res) => {
        cb(res.data);
      });
    },
    //选择查询结果
    handleSelect(item) {
      this.search = item;
      this.$store.state.search = this.search;
    },
    find() {
      console.log(1)
      this.$store.state.search = this.search;
    },
  },
  watch: {
    //监控搜索框显示影藏
    $route() {
      console.log(this.$route.path);
      if (this.$route.path == "/haishi") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.haishi {
  background: #f5f5f5;
  padding-bottom: 40px;
}
.mian1200 {
  width: 1200px;
  margin: 0 auto;
}
//搜索
.search_box {
  width: 100%;
  height: 84px;
  background: #ffffff;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin-top: 12px;
  .search {
    display: flex;
    padding: 20px 0px;
    /deep/ .el-autocomplete {
      width: 100%;
      .el-input__inner {
        background: #fafafa;
        // border-right: none;
        &.el-input__inner:focus {
          border-color: #d99d66;
          // border-right: none;
        }
      }
      .el-input-group__append {
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        color: #935d14;
        cursor: pointer;
      }
    }
  }
}
//作用子组件的分页器样式
/deep/ .el-pagination {
  .btn-prev,
  .btn-next {
    border: 1px solid #ededed;
    background: white;
    border-radius: 4px 8px 4px 8px;
  }
  .el-pager li {
    border-radius: 4px 8px 4px 8px;
    border: 1px solid #ededed;
    background: white;
    font-weight: 400;
    color: #999999;
  }
  .el-pager li:hover {
    background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
    color: white !important;
    opacity: 0.8;
  }
  .el-pager li.active {
    background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
    color: white;
  }
}
</style>
<style lang="scss">
//统一子页面element-ui样式
//分页器
.fenye {
  height: 106px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
